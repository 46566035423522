import { colors, hooks, translate } from '@pay24/common'
import React, { useCallback, useRef, useState } from 'react'
import { Animated, PanResponder, StyleSheet, Text, View } from 'react-native'
import Icon from './Icon'

const { useTranslation } = translate
const { useTheme } = hooks

type Props = {
  disabled?: boolean
  title: string
  submitApplication?: () => void
  builderCreatedInvoice?: boolean
  loading?: boolean
  backgroundText?: string
  onSwipe: () => void
}

export const SwipeableButton = ({
  disabled,
  loading,
  title,
  backgroundText,
  onSwipe,
}: Props) => {
  const [swipeCompleted, setSwipeCompleted] = useState(false)
  const [buttonWidth, setButtonWidth] = useState<number>(0)
  const { t } = useTranslation()
  const { isLight, primary } = useTheme()

  const onSwipeSuccess = useCallback(() => {
    setSwipeCompleted(true)
    onSwipe()
  }, [onSwipe])

  return (
    <View
      style={styles.container}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout
        setButtonWidth(width)
      }}
    >
      <View
        style={[
          styles.rail,
          {
            backgroundColor: swipeCompleted
              ? primary
              : isLight
              ? colors.borderLight
              : colors.borderDark,
          },
        ]}
      >
        <Text style={[styles.title, { color: primary }]}>{t(title)}</Text>
      </View>
      {buttonWidth ? (
        <SwipeButton
          onSwipe={onSwipeSuccess}
          textColor={primary}
          backgroundText={t(backgroundText || '')}
          swipeCompleted={swipeCompleted}
          buttonWidth={buttonWidth}
          disabled={disabled || loading}
        />
      ) : null}
    </View>
  )
}

interface SwipeButtonProperty {
  backgroundText: string
  onSwipe: () => void
  textColor?: string
  swipeCompleted: boolean
  buttonWidth: number
  disabled?: boolean
}

const SwipeButton = ({
  backgroundText,
  onSwipe,
  textColor,
  swipeCompleted = false,
  buttonWidth,
  disabled = false,
}: SwipeButtonProperty) => {
  const translateX = useRef(new Animated.Value(0)).current

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => !disabled,
      onPanResponderMove: (_, gestureState) => {
        if (!disabled && gestureState.dx > 0) {
          translateX.setValue(gestureState.dx)
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        if (disabled) return

        if (buttonWidth && gestureState.dx > buttonWidth * 0.8) {
          Animated.timing(translateX, {
            toValue: buttonWidth - 80,
            duration: 600,
            useNativeDriver: true,
          }).start(() => {
            if (onSwipe) onSwipe()
          })
        } else {
          Animated.timing(translateX, {
            toValue: 0,
            duration: 600,
            useNativeDriver: true,
          }).start()
        }
      },
    }),
  ).current

  return swipeCompleted ? (
    <Text style={{ color: colors.white, textAlign: 'center' }}>
      {backgroundText}...
    </Text>
  ) : (
    <Animated.View
      {...panResponder.panHandlers}
      style={[
        styles.thumb,
        {
          transform: [{ translateX }],
          backgroundColor: textColor,
          opacity: disabled ? 0.5 : 1,
        },
      ]}
    >
      <Icon name={'chevron-double-right'} color={colors.white} />
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 42,
    borderRadius: 25,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  rail: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
  },
  thumb: {
    width: 60,
    height: 42,
    borderRadius: 25,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
